import * as React from 'react';
import { Stream } from '@cloudflare/stream-react';
import { getMultipleFeaturesBladeFromThreeFeaturesBlade } from '../util';
import { Container, Row, Col, ColProps } from 'react-bootstrap';
import { H5, H3, Div, H4, A, H2, P } from '../../Elements';
import { MarkdownBlock } from '../../MarkdownBlock';
import {
  ContentfulAssetFile,
  ContentfulAssetFileURL,
} from '../../ContentfulAssetFile/ContentfulAssetFile';
import { Color, SpaceIndex, PaddingStyleProps, Breakpoints } from '../../Elements/types';
import { RelatedBlade } from '../../../../contentful/blades';
import { ContentfulBladeMultipleFeatures } from '../../../../contentful/content-types/blades/blade-four-features';
import { ContentfulBladeThreeFeatures } from '../../../../contentful/content-types/blades/blade-three-features';
import { BackgroundColor } from '../../../../contentful/content-types/blades/common/interfaces/background-color.interface';
import { Subtitle } from '../../Subtitle';
import sectionPadding from '../../BladePadding';
import { Lazy } from '../../Lazy';
import { Divider } from '../../Divider';
import { InfoBlocks } from './InfoBlocks';

export function isContentfulBladeMultipleFeatures(
  blade: RelatedBlade
): blade is ContentfulBladeMultipleFeatures | ContentfulBladeThreeFeatures {
  return (
    blade.contentTypeId === 'bladeFourFeatures' || blade.contentTypeId === 'bladeThreeFeatures'
  );
}

interface FeaturesBladeProps {
  blade: ContentfulBladeMultipleFeatures | ContentfulBladeThreeFeatures;
}

const getClassnameFromBackgroundColor: { [key in BackgroundColor]: Color } = {
  white: 'white',
  'light-blue': 'blue5',
};

export const FeaturesBlade: React.FC<FeaturesBladeProps> = props => {
  let blade: ContentfulBladeMultipleFeatures;
  if (props.blade.contentTypeId === 'bladeThreeFeatures') {
    blade = getMultipleFeaturesBladeFromThreeFeaturesBlade(props.blade);
  } else {
    blade = props.blade;
  }
  const bladeStyle = blade.bladeStyle || '';
  const featureDesktopColSize = getDesktopColSizeFromBlade(blade);

  const customFeaturePadding = (index: number): SpaceIndex | Breakpoints<SpaceIndex> => {
    if (blade.features) {
      const totalFeatures = blade.features.length - 1;
      if (totalFeatures === index) {
        return [0, 6];
      }
    }
    return [6, 7];
  };

  const regularStyle = () => {
    return (
      <Row>
        <Col xs={12}>
          {blade.subtitle && blade.title && (
            <Subtitle marginBottom={[3, 1]}>{blade.subtitle}</Subtitle>
          )}
          {blade.subtitle && !blade.title && (
            <Subtitle marginBottom={[3, 3]}>{blade.subtitle}</Subtitle>
          )}

          {blade.title && (
            <H3 marginTop={0} marginBottom={blade.copy ? 0 : [6, 6, 6, 7]}>
              {blade.title}
            </H3>
          )}
          {blade.copy && (
            <Div marginTop={2}>
              <MarkdownBlock
                source={blade.copy}
                className="flex-grow-2"
                pClassName={`mt2`}
                paragraphStyle={2}
                renderers={{
                  paragraph: ({ children }) => (
                    <P marginBottom={[6, 6, 6, 7]} className={`body-1`}>
                      {children}
                    </P>
                  ),
                }}
              />
            </Div>
          )}
        </Col>
        {blade.features &&
          blade.features.map((feature, i) => (
            <Col key={feature.contentfulId} xs={12} xl={featureDesktopColSize}>
              <Div
                heightPercentage={100}
                display="flex"
                flexDirection="column"
                flex="auto"
                paddingBottom={customFeaturePadding(i)}
              >
                {feature.streamId && (
                  <>
                    <ContentfulAssetFileURL
                      assetFile={feature.streamThumbnailAssetFile}
                      fallBackUrl={feature.streamThumbnail?.file?.publicURL}
                    >
                      {({ url }) =>
                        (feature.streamId && (
                          <Div>
                            <Lazy>
                              <Stream
                                src={feature.streamId}
                                preload="auto"
                                controls
                                poster={
                                  url || feature.streamThumbnail?.file?.publicURL || undefined
                                }
                              />
                            </Lazy>
                          </Div>
                        )) ||
                        null
                      }
                    </ContentfulAssetFileURL>
                    <H4 marginTop={2} marginBottom={0}>
                      {feature.title}
                    </H4>
                  </>
                )}

                {feature.imageAssetFile && (
                  <>
                    <ContentfulAssetFile
                      assetFile={feature.imageAssetFile}
                      className={`mw-100 center`}
                    />
                    {feature.title && (
                      <H5 marginTop={2} marginBottom={0}>
                        {feature.title}
                      </H5>
                    )}
                  </>
                )}

                {!feature.streamId && (
                  <ContentfulAssetFile
                    assetFile={feature.iconAssetFile}
                    fallBackUrl={feature.icon?.file?.publicURL}
                    className={`icon-1`}
                  />
                )}

                {!feature.streamId && !feature.imageAssetFile && feature.icon && feature.title && (
                  <>
                    {feature.displayBorder && <Divider paddingVertical={0} paddingBottom={3} />}
                    <H5 marginTop={2} marginBottom={0}>
                      {feature.title}
                    </H5>
                  </>
                )}

                {!feature.streamId && !feature.imageAssetFile && !feature.icon && feature.title && (
                  <>
                    {feature.displayBorder && <Divider paddingVertical={0} paddingBottom={3} />}
                    <H3 marginTop={0} marginBottom={0}>
                      {feature.title}
                    </H3>
                  </>
                )}

                {feature.description && (
                  <MarkdownBlock
                    source={feature.description}
                    className="flex-grow-2"
                    listStyle="feature"
                    pClassName={`mt2`}
                    paragraphStyle={2}
                    renderers={{
                      paragraph: ({ children }) => (
                        <P marginBottom={feature.learnMoreUrl ? 4 : 0} className={`body-2 mt2`}>
                          {children}
                        </P>
                      ),
                    }}
                  />
                )}

                {feature.learnMoreUrl && (
                  <A
                    href={feature.learnMoreUrl}
                    className="learn-more"
                    data-tracking-category="newhome-features-blade"
                    data-tracking-action="click"
                    data-tracking-label={`features_learn_more-[${feature.learnMoreUrl}]`}
                  >
                    {feature.learnMoreText ? feature.learnMoreText : 'Learn More'}
                  </A>
                )}
              </Div>
            </Col>
          ))}
      </Row>
    );
  };

  const getBladeStyle = () => {
    if (bladeStyle === 'Info Blocks') {
      return <InfoBlocks blade={blade} />;
    } else {
      return regularStyle();
    }
  };

  return (
    <Div backgroundColor={getClassnameFromBackgroundColor[blade.backgroundColor] || 'white'}>
      <Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
        <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>{getBladeStyle()}</Div>
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
      </Container>
    </Div>
  );
};

function getDesktopColSizeFromBlade(blade: ContentfulBladeMultipleFeatures): ColProps['xs'] {
  const bladeStyle = blade.bladeStyle;
  if (
    bladeStyle === 'Feature Blocks - 2 columns' ||
    bladeStyle === 'Feature Blocks - 3 columns' ||
    bladeStyle === 'Feature Blocks - 4 columns'
  ) {
    if (bladeStyle === 'Feature Blocks - 2 columns') {
      return 6;
    } else if (bladeStyle === 'Feature Blocks - 3 columns') {
      return 4;
    } else if (bladeStyle === 'Feature Blocks - 4 columns') {
      return 3;
    }
  }

  // This supports the old way the blade determined columns, need this to support entries that have been configured before you can choose custom columns
  if (blade.features) {
    if (blade.features.length === 2) {
      return 6;
    } else if (blade.features.length === 3) {
      return 4;
    } else if (blade.features.length === 4) {
      return 3;
    }
  }

  // The default number of columns when blade style not selected, make the features span 3 columns each, which will wrap
  return 4;
}
