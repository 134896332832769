import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { ContentfulBladeMultipleFeatures } from '../../../../contentful/content-types/blades/blade-four-features';
import { ContentfulBladeThreeFeatures } from '../../../../contentful/content-types/blades/blade-three-features';
import {
  ContentfulAssetFileURL,
  ContentfulAssetFile,
} from '../../ContentfulAssetFile/ContentfulAssetFile';
import { Stream } from '@cloudflare/stream-react';

import { H4, H5, P, A, Div, H2, H3 } from '../../Elements';
import { MarkdownBlock } from '../../MarkdownBlock';
import { Lazy } from '../../Lazy';
import { Divider } from '../../Divider';

interface FeaturesBladeProps {
  blade: ContentfulBladeMultipleFeatures | ContentfulBladeThreeFeatures;
}
export const InfoBlocks: React.FC<FeaturesBladeProps> = ({ blade }) => {
  return (
    <Row>
      <Col sm={12} lg={4}>
        <H2 paddingBottom={7}>{blade.title}</H2>
      </Col>
      <Col lg={8}>
        <Row>
          {blade.features &&
            blade.features.map((feature, i) => (
              <Col key={feature.contentfulId} xs={12} lg={6}>
                <Div
                  display="flex"
                  flexDirection="column"
                  flex="auto"
                  marginBottom={
                    (blade.features && blade.features.length - 1 === i) ||
                    (blade.features && blade.features.length - 2 === i)
                      ? [6, 6, 6, 0]
                      : [6, 9]
                  }
                >
                  {feature.streamId && (
                    <>
                      <ContentfulAssetFileURL
                        assetFile={feature.streamThumbnailAssetFile}
                        fallBackUrl={feature.streamThumbnail?.file?.publicURL}
                      >
                        {({ url }) =>
                          (feature.streamId && (
                            <Div>
                              <Lazy>
                                <Stream
                                  src={feature.streamId}
                                  preload="auto"
                                  controls
                                  poster={
                                    url || feature.streamThumbnail?.file?.publicURL || undefined
                                  }
                                />
                              </Lazy>
                            </Div>
                          )) ||
                          null
                        }
                      </ContentfulAssetFileURL>
                      <H4 marginBottom={0}>{feature.title}</H4>
                    </>
                  )}

                  {feature.imageAssetFile && (
                    <>
                      <ContentfulAssetFile
                        assetFile={feature.imageAssetFile}
                        className={`mw-100 center`}
                      />
                      <H5 marginTop={2} marginBottom={0}>
                        {feature.title}
                      </H5>
                    </>
                  )}

                  {!feature.streamId && (
                    <ContentfulAssetFile
                      assetFile={feature.iconAssetFile}
                      fallBackUrl={feature.icon?.file?.publicURL}
                      className={`icon-1`}
                    />
                  )}

                  {((!feature.streamId && !feature.imageAssetFile && feature.icon) ||
                    feature.iconAssetFile) && (
                    <H5 marginTop={2} marginBottom={0}>
                      {feature.title}
                    </H5>
                  )}

                  {!feature.streamId &&
                    !feature.imageAssetFile &&
                    !feature.icon &&
                    !feature.iconAssetFile && (
                      <>
                        {feature.displayBorder && <Divider />}
                        <H3 marginBottom={0}>{feature.title}</H3>
                      </>
                    )}

                  {feature.description && (
                    <MarkdownBlock
                      source={feature.description}
                      className="flex-grow-2"
                      listStyle="feature"
                      paragraphStyle={2}
                      renderers={{
                        paragraph: ({ children }) => (
                          <P
                            marginBottom={
                              feature.learnMoreUrl ||
                              (blade.features && blade.features.length - 1 === i)
                                ? 4
                                : 0
                            }
                            className="body-2"
                          >
                            {children}
                          </P>
                        ),
                      }}
                      pClassName="mt2"
                    />
                  )}
                  {feature.learnMoreUrl && (
                    <A
                      href={feature.learnMoreUrl}
                      className="learn-more"
                      data-tracking-category="newhome-features-blade"
                      data-tracking-action="click"
                      data-tracking-label={`features_learn_more-[${feature.learnMoreUrl}]`}
                    >
                      {feature.learnMoreText ? feature.learnMoreText : 'Learn More'}
                    </A>
                  )}
                </Div>
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

export default InfoBlocks;
